import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Product } from "../../../utils/types";
import "./FilteredWatch.css";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";
import NavDropdown from "../dropdown/NavDropdown";
import {
  DoubleArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowRightIcon,
  ChevronUpIcon,
  WidthIcon,
} from "@radix-ui/react-icons";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "../../../shad-components/ui/toggle-group";

const optionsGear = [
  { value: "Automatik", label: "Automatik" },
  { value: "Chronograph Automatik", label: "Chronograph Automatik" },
  { value: "Chronograph Quarz", label: "Chronograph Quarz" },
  { value: "Chronograph Handaufzug", label: "Chronograph Handaufzug" },
  { value: "Funk", label: "Funkwerk" },
  { value: "Funk Solar", label: "Funkwerk Solar" },
  { value: "hand", label: "Handaufzug" },
  { value: "Quarz", label: "Quarz" },
  { value: "Solar", label: "Solar" },
  { value: "Smart Watch", label: "Smart Watch" },
];

const optionsBrand = [
  { value: "Abeler & Söhne", label: "Abeler & Söhne" },
  { value: "Adora", label: "Adora" },
  { value: "AMS", label: "AMS" },
  { value: "Atlanta", label: "Atlanta" },
  { value: "Bauhaus", label: "Bauhaus" },
  { value: "Bering", label: "Bering" },
  { value: "Boccia", label: "Boccia" },
  { value: "BRUNO SÖHNLE", label: "Bruno Söhnle - Glashütte" },
  { value: "CASIO", label: "Casio" },
  { value: "CEM", label: "CEM" },
  { value: "G-SHOCK", label: "G-SHOCK" },
  { value: "Iron Annie", label: "Iron Annie" },
  { value: "Laimer", label: "Laimer" },
  { value: "Lorus", label: "Lorus" },
  { value: "Mido", label: "Mido" },
  { value: "Mühle Glashütte", label: "Mühle Glashütte" },
  { value: "Pulsar", label: "Pulsar" },
  { value: "RHYTHM", label: "RHYTHM" },
  { value: "Seiko Clocks", label: "Seiko Clocks" },
  { value: "SEIKO", label: "Seiko Watches" },
  { value: "STORM London", label: "STORM London" },
  { value: "techno line", label: "techno line" },
  { value: "Tissot", label: "Tissot" },
  { value: "Trollbeads", label: "TROLLBEADS" },
  { value: "TUTIMA Glashütte", label: "TUTIMA Glashütte" },
  { value: "UNION Glashütte", label: "UNION Glashütte" },
];

const optionsSort = [
  { value: "ASC", label: "Preis aufsteigend" },
  { value: "DESC", label: "Preis absteigend" },
];

const RepeatedDiv: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-3 pb-4 pl-8 pr-8 pt-8 shadow-xl">
      <div className="h-[225px] w-full rounded-xl bg-gray-300" />
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="h-2 w-[150px] bg-gray-300" />
        <div className="h-5 w-[220px] bg-gray-300" />
        <div className="h-5 w-[250px] bg-gray-300" />
        <div className="h-2 w-[100px] bg-gray-300" />
      </div>
      <div className="flex flex-col items-center justify-center gap-1 pt-12">
        <div className="h-1 w-[100px] bg-gray-300"></div>
        <div className="h-5 w-[100px] bg-gray-300"></div>
      </div>
    </div>
  );
};

const FilteredPaginatedProductGridWatch = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page") || "1");
  const [selectedButton, setSelectedButton] = useState("Unisex");
  const [page, setPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedBrand, setSelectedBrand] = useState<string | null>(
    searchParams.get("brand") || localStorage.getItem("selectedBrand"),
  );
  const [selectedClockwork, setSelectedClockwork] = useState<string | null>(
    searchParams.get("clockworkType") ||
      localStorage.getItem("selectedClockwork"),
  );
  const [selectedSort, setSelectedSort] = useState<string | null>(
    searchParams.get("sortBy") || localStorage.getItem("selectedSort"),
  );
  const [selectedProductGroup, setSelectedProductGroup] = useState<
    string | null
  >(
    searchParams.get("productGroup") ||
      localStorage.getItem("selectedProductGroup"),
  );
  const [selectedProductFamily, setSelectedProductFamily] = useState<
    string | null
  >(
    searchParams.get("productFamily") ||
      localStorage.getItem("selectedProductFamily"),
  );
  const [minPrice, setMinPrice] = useState<number | null>(
    parseFloat(searchParams.get("minPrice") || "") || null,
  );
  const [maxPrice, setMaxPrice] = useState<number | null>(
    parseFloat(searchParams.get("maxPrice") || "") || null,
  );
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const handleSelectBrandChange = (
    selectedOption: { value: string; label: string } | null,
  ) => {
    const newBrand = selectedOption ? selectedOption.value : null;
    setSelectedBrand(newBrand);
    localStorage.setItem("selectedBrand", newBrand || "");
    updateSearchParams("brand", newBrand || "");
  };
  

  const handleSelectGearChange = (
    selectedOption: { value: string; label: string } | null,
  ) => {
    const newClockwork = selectedOption ? selectedOption.value : null;
    setSelectedClockwork(newClockwork);
    localStorage.setItem("selectedClockwork", newClockwork || "");
    updateSearchParams(
      "clockworkType",
      newClockwork ? encodeURIComponent(newClockwork) : "",
    );
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSelectSortChange = (
    selectedOption: { value: string; label: string } | null,
  ) => {
    if (selectedOption) {
      if (selectedOption.value === 'az') {
        setSelectedSort('az');
        localStorage.setItem("selectedSort", 'az');
        updateSearchParams("sortDirection", "ASC");
      } else if (selectedOption.value === 'za') {
        setSelectedSort('za');
        localStorage.setItem("selectedSort", 'za');
        updateSearchParams("sortDirection", "DESC");
      } else if (selectedOption.value === 'ASC' || selectedOption.value === 'DESC') {
        setSelectedSort(selectedOption.value);
        localStorage.setItem("selectedSort", selectedOption.value);
        updateSearchParams("sortBy", "price");
        updateSearchParams("sortDirection", selectedOption.value);
      }
    } else {
      setSelectedSort(null);
      localStorage.removeItem("selectedSort");
      updateSearchParams("sortBy", "price");
      updateSearchParams("sortDirection", "ASC");
    }
  };
  

  const handleSelectChange = (value: string) => {
    localStorage.setItem("selectedProductFamily", value);
    setSelectedProductFamily(value);
    updateSearchParams("productFamily", value);
  };

  const handleMinPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setMinPrice(isNaN(value) ? null : value);
  };

  const handleMaxPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setMaxPrice(isNaN(value) ? null : value);
  };

  const handlePageSelectInc = () => {
    const newPage = page < totalPages ? page + 1 : page;
    updateSearchParams("page", newPage.toString());
    setPage(newPage);
    scrollToTop();
  };

  const handlePageSelectDec = () => {
    const newPage = page > 1 ? page - 1 : 1;
    updateSearchParams("page", newPage.toString());
    setPage(newPage);
    scrollToTop();
  };

  const handlePageSelectFirst = () => {
    updateSearchParams("page", "1");
    setPage(1);
    scrollToTop();
  };

  const handlePageSelectLast = () => {
    updateSearchParams("page", totalPages.toString());
    setPage(totalPages);
    scrollToTop();
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, page - 2);
    const endPage = Math.min(totalPages, page + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`pagination-button flex h-8 items-center justify-center border border-gray-300 px-3 leading-tight text-gray-500 hover:bg-gray-300 hover:text-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
            i === page ? "bg-gray-300" : ""
          }`}
          onClick={() => updateSearchParams("page", i.toString())}
        >
          {i}
        </button>,
      );
    }

    return pageNumbers;
  };

  const resetFilters = () => {
    setSelectedBrand(null);
    setSelectedClockwork(null);
    setSelectedSort(null);
    setSelectedProductGroup(null);
    setMinPrice(null);
    setMaxPrice(null);
    setSelectedProductFamily("");
    setSearchTerm("");
    localStorage.removeItem("selectedBrand");
    localStorage.removeItem("selectedClockwork");
    localStorage.removeItem("selectedSort");
    localStorage.removeItem("selectedProductGroup");
    localStorage.removeItem("selectedProductfamily");
    navigate("/UHREN");
  };

  const updateSearchParams = (key: string, value: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    navigate(`/UHREN?${searchParams.toString()}`, { replace: true });
  };

  const fetchProducts = useCallback(async () => {
    let url = `/api/aeb/products?productLine=UHREN&page=${page}`;
    if (selectedBrand) url += `&brand=${encodeURIComponent(selectedBrand)}`;
    if (selectedClockwork) url += `&clockworkType=${encodeURIComponent(selectedClockwork)}`;
    if (selectedSort) url += `&sortBy=price&sortDirection=${encodeURIComponent(selectedSort)}`;
    if (selectedProductGroup) url += `&productGroup=${encodeURIComponent(selectedProductGroup)}`;
    if (selectedProductFamily) url += `&productFamily=${encodeURIComponent(selectedProductFamily)}`;
    if (minPrice !== null) url += `&minPrice=${minPrice}`;
    if (maxPrice !== null) url += `&maxPrice=${maxPrice}`;
    try {
      setIsLoading(true);
      const response = await fetch(url);
      const data = await response.json();
      if (Array.isArray(data)) {
        setProducts(data);
      } else {
        setProducts([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Fehler beim Laden der Produkte:", error);
      setIsLoading(false);
    }
  }, [
    page,
    selectedBrand,
    selectedClockwork,
    selectedSort,
    selectedProductGroup,
    selectedProductFamily,
    minPrice,
    maxPrice,
  ]);  

  const fetchProductPages = useCallback(async () => {
    let url = `/api/aeb/product-pages?productLine=UHREN`;
    if (selectedBrand) url += `&brand=${selectedBrand}`;
    if (selectedClockwork) url += `&clockworkType=${selectedClockwork}`;
    if (selectedProductGroup) url += `&productGroup=${selectedProductGroup}`;
    if (minPrice !== null) url += `&minPrice=${minPrice}`;
    if (maxPrice !== null) url += `&maxPrice=${maxPrice}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Fehler beim Laden der Seitenanzahl:", error);
    }
  }, [
    selectedBrand,
    selectedClockwork,
    selectedProductGroup,
    minPrice,
    maxPrice,
  ]);

  const fetchSearchProducts = useCallback(
    async (term: string) => {
      let url = `/api/aeb/search?term=${term}&page=${page}&productLine=UHREN`;
      try {
        setIsLoading(true);
        const response = await fetch(url);
        const data = await response.json();
        if (data.products.length === 0) {
          setProducts([]);
        } else {
          setProducts(data.products);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Fehler beim Laden der Produkte:", error);
        setIsLoading(false);
      }
    },
    [page],
  );

  const fetchSearchProductPages = useCallback(async (term: string) => {
    let url = `/api/aeb/search-pages?term=${term}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Fehler beim Laden der Seitenanzahl:", error);
    }
  }, []);

  useEffect(() => {
    setPage(initialPage);
  }, [initialPage]);

  useEffect(() => {
    if (searchTerm) {
      fetchSearchProducts(searchTerm);
      fetchSearchProductPages(searchTerm);
    } else {
      fetchProducts();
      fetchProductPages();
    }
  }, [
    location.search,
    fetchProducts,
    fetchProductPages,
    fetchSearchProducts,
    fetchSearchProductPages,
    searchTerm,
  ]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openFilter = () => setIsFilterOpen(true);
  const closeFilter = () => setIsFilterOpen(false);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    fetchSearchProducts(searchTerm);
    fetchSearchProductPages(searchTerm);
  };

  return (
    <>
      <div className="watchesShopPage-container">
        <div className="mx-auto flex w-full max-w-[1560px] flex-col items-center justify-between border-gray-300 py-2">
          <span className="flex xl:hidden">
            <button
              onClick={openFilter}
              className="flex items-center justify-center gap-2 rounded-xl border-[1px] border-gray-600 px-2 py-1 xl:text-lg"
            >
              <span>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 1.5C5 1.22386 4.77614 1 4.5 1C4.22386 1 4 1.22386 4 1.5L4 7C4 7.01671 4.00082 7.03323 4.00242 7.04952C2.86009 7.28022 2 8.28967 2 9.5C2 10.7103 2.86009 11.7198 4.00242 11.9505C4.00082 11.9668 4 11.9833 4 12V13.5C4 13.7761 4.22386 14 4.5 14C4.77614 14 5 13.7761 5 13.5V12C5 11.9833 4.99918 11.9668 4.99758 11.9505C6.1399 11.7198 7 10.7103 7 9.5C7 8.28967 6.1399 7.28022 4.99758 7.04952C4.99918 7.03323 5 7.01671 5 7L5 1.5ZM11 1.5C11 1.22386 10.7761 1 10.5 1C10.2239 1 10 1.22386 10 1.5V3C10 3.01671 10.0008 3.03323 10.0024 3.04952C8.8601 3.28022 8 4.28967 8 5.5C8 6.71033 8.8601 7.71978 10.0024 7.95048C10.0008 7.96677 10 7.98329 10 8V13.5C10 13.7761 10.2239 14 10.5 14C10.7761 14 11 13.7761 11 13.5V8C11 7.98329 10.9992 7.96677 10.9976 7.95048C12.1399 7.71978 13 6.71033 13 5.5C13 4.28967 12.1399 3.28022 10.9976 3.04952C10.9992 3.03323 11 3.01671 11 3V1.5ZM4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11C5.32843 11 6 10.3284 6 9.5C6 8.67157 5.32843 8 4.5 8ZM9 5.5C9 4.67157 9.67157 4 10.5 4C11.3284 4 12 4.67157 12 5.5C12 6.32843 11.3284 7 10.5 7C9.67157 7 9 6.32843 9 5.5Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
              Filter
            </button>
          </span>
          <NavDropdown isFilterOpen={isFilterOpen} closeFilter={closeFilter}>
            <p className="text-gray-500">Produktdetails</p>
            <div className="max-w-screen flex flex-col items-start justify-center gap-4 pb-8">
              <Select
                options={optionsBrand}
                placeholder="Marke"
                value={
                  selectedBrand
                    ? optionsBrand.find(
                        (option) => option.value === selectedBrand,
                      )
                    : null
                }
                className="w-full"
                onChange={handleSelectBrandChange}
              />
              <Select
                options={optionsGear}
                placeholder="Uhrwerk"
                value={
                  selectedClockwork
                    ? optionsGear.find(
                        (option) => option.value === selectedClockwork,
                      )
                    : null
                }
                className="w-full"
                onChange={handleSelectGearChange}
              />
              <Select
                options={optionsSort}
                placeholder="Sortierung"
                value={
                  selectedSort
                    ? optionsSort.find(
                        (option) => option.value === selectedSort,
                      )
                    : null
                }
                className="w-full"
                onChange={handleSelectSortChange}
              />
            </div>
            <p className="text-gray-500">Geschlecht</p>
            <div className="inline-flex rounded-md shadow-sm" role="group">
              <ToggleGroup type="single" variant={"outline"}>
                <ToggleGroupItem value="bold" aria-label="Toggle bold">
                  <div onClick={() => handleSelectChange("Unisex")}>Unisex</div>
                </ToggleGroupItem>
                <ToggleGroupItem value="italic" aria-label="Toggle italic">
                  <div onClick={() => handleSelectChange("Damenuhren")}>
                    Damenuhren
                  </div>
                </ToggleGroupItem>
                <ToggleGroupItem
                  value="strikethrough"
                  aria-label="Toggle strikethrough"
                >
                  <div onClick={() => handleSelectChange("Herrenuhren")}>
                    Herrenuhren
                  </div>
                </ToggleGroupItem>
              </ToggleGroup>
              {/* <button
                type="button"
                className={`rounded-s-lg border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500 ${selectedButton === "Unisex" ? "bg-blue-200 text-blue-700" : ""}`}
                onClick={() => handleSelectChange("Unisex")}
              >
                Unisex
              </button>
              <button
                type="button"
                className={`border-b border-t border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700 active:text-blue-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500 ${selectedButton === "Damenuhren" ? "bg-blue-200 text-blue-700" : ""}`}
                onClick={() => handleSelectChange("Damenuhren")}
              >
                Damenuhren
              </button>
              <button
                type="button"
                className={`rounded-e-lg border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white dark:focus:ring-blue-500 ${selectedButton === "Herrenuhren" ? "bg-blue-200 text-blue-700" : ""}`}
                onClick={() => handleSelectChange("Herrenuhren")}
              >
                Herrenuhren
              </button> */}
            </div>
            <p className="text-gray-500">Preis</p>
            <span className="flex h-full items-center justify-between gap-2">
              <input
                className="rounded-lg border-[1px] border-gray-300 px-2 py-1"
                type="number"
                placeholder="0"
                value={minPrice !== null ? minPrice : ""}
                onChange={handleMinPriceChange}
              />
              <WidthIcon />
              <input
                className="rounded-lg border-[1px] border-gray-300 px-2 py-1"
                type="number"
                placeholder="10000"
                value={maxPrice !== null ? maxPrice : ""}
                onChange={handleMaxPriceChange}
              />
            </span>
          </NavDropdown>
          <span className="hidden w-max items-center justify-start gap-4 lg:flex">
            <div className="flex w-max items-start justify-center gap-4">
              <Select
                options={optionsBrand}
                placeholder="Marke"
                value={
                  selectedBrand
                    ? optionsBrand.find(
                        (option) => option.value === selectedBrand,
                      )
                    : null
                }
                className="w-[30ch]"
                onChange={handleSelectBrandChange}
              />
              <Select
                options={optionsGear}
                placeholder="Uhrwerk"
                value={
                  selectedClockwork
                    ? optionsGear.find(
                        (option) => option.value === selectedClockwork,
                      )
                    : null
                }
                className="w-[20ch]"
                onChange={handleSelectGearChange}
              />
              <Select
                options={optionsSort}
                placeholder="Sortierung"
                value={
                  selectedSort
                    ? optionsSort.find(
                        (option) => option.value === selectedSort,
                      )
                    : null
                }
                className="w-[20ch]"
                onChange={handleSelectSortChange}
              />
              <div className="inline-flex rounded-md shadow-sm" role="group">
                <ToggleGroup type="single" variant={"default"}>
                  <ToggleGroupItem value="bold" aria-label="Toggle bold">
                    <div onClick={() => handleSelectChange("Unisex")}>
                      Unisex
                    </div>
                  </ToggleGroupItem>
                  <ToggleGroupItem value="italic" aria-label="Toggle italic">
                    <div onClick={() => handleSelectChange("Damenuhren")}>
                      Damenuhren
                    </div>
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    value="strikethrough"
                    aria-label="Toggle strikethrough"
                  >
                    <div onClick={() => handleSelectChange("Herrenuhren")}>
                      Herrenuhren
                    </div>
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
              <span className="flex h-full items-center justify-between gap-2">
                <input
                  className="rounded-lg border-[1px] border-gray-300 px-2 py-1"
                  type="number"
                  placeholder="0"
                  value={minPrice !== null ? minPrice : ""}
                  onChange={handleMinPriceChange}
                />
                <WidthIcon />
                <input
                  className="rounded-lg border-[1px] border-gray-300 px-2 py-1"
                  type="number"
                  placeholder="10000"
                  value={maxPrice !== null ? maxPrice : ""}
                  onChange={handleMaxPriceChange}
                />
              </span>
            </div>
            <button onClick={resetFilters}>Filter zurücksetzen</button>
          </span>
        </div>
        <section className="bg-gray-100">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="mt-8 flex items-center justify-center">
              <h1 className="text-xl xl:text-6xl">Unser Uhren-Sortiment</h1>
            </div>
            <div className="w-full">
              <div className="mx-auto grid w-full max-w-[1560px]">
                <div className="pagination mt-2 flex w-full flex-col items-center justify-between gap-12 lg:flex-row">
                  <div className="flex w-full items-center justify-center gap-2 px-4 lg:px-0">
                    <div className="relative flex w-full items-center justify-center">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                        placeholder="Suche..."
                        className="w-full rounded-lg border-[1px] border-gray-300 px-4 py-2"
                      />
                    </div>
                    <button
                      onClick={handleSearchSubmit}
                      className="rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                    >
                      Suchen
                    </button>
                  </div>
                  {totalPages === 0 ? (
                    <></>
                  ) : (
                    <div className="flex h-8 items-center -space-x-px text-sm">
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center rounded-s-lg border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectFirst}
                      >
                        <DoubleArrowLeftIcon />
                      </button>
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectDec}
                      >
                        <ChevronLeftIcon />
                      </button>
                      {renderPageNumbers()}
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center border border-s-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectInc}
                      >
                        <ChevronRightIcon />
                      </button>
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center rounded-e-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectLast}
                      >
                        <DoubleArrowRightIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {!isLoading ? (
                products.length > 0 ? (
                  <div className="mx-auto mt-5 grid w-full max-w-[1560px] grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-4">
                    {products &&
                      products.map((product, index) => (
                        <Link
                          className="pc__detailLink w-full bg-white pb-4 pl-8 pr-8 pt-8 shadow-lg"
                          to={`/${product.productLine}/${product.articleId}`}
                          key={product.articleId}
                        >
                          <div
                            key={product.id}
                            className="flex h-full max-w-full flex-col items-center justify-between rounded-lg"
                          >
                            <div className="flex flex-col items-center justify-center">
                              {product.mainImage && (
                                <img
                                  width={300}
                                  height={300}
                                  src={`data:image/jpeg;base64,${product.mainImage}`}
                                  alt={product.productName}
                                />
                              )}
                              <div className="mt-4 flex flex-col">
                                <p className="text-center text-xs text-gray-500 lg:text-base">
                                  {product.brand}
                                </p>
                                <h3 className="text-center text-base lg:text-lg">
                                  {product.productName}
                                </h3>
                                <p className="text-center text-xs text-gray-500 lg:text-base">
                                  {product.articleId}
                                </p>
                                <p className="text-center text-xs text-gray-500 lg:text-base">
                                  {product.productFamily}&nbsp;&#8722;&nbsp;
                                  {product.productType}&nbsp;&#8722;&nbsp;
                                  {product.articleGroup}
                                </p>
                              </div>
                            </div>
                            <p className="mt-8 border-t-[1px] border-gray-400 text-center text-lg font-bold dark:border-gray-700">
                              {formatPrice(product.price.toString())}€
                            </p>
                          </div>
                        </Link>
                      ))}
                  </div>
                ) : (
                  <div className="mx-auto mt-5 flex items-center justify-center">
                    <p className="text-lg text-gray-500">
                      Keine Produkte gefunden
                    </p>
                  </div>
                )
              ) : (
                <div className="mx-auto mt-5 grid w-full max-w-[1560px] grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-4">
                  {Array.from({ length: 16 }).map((_, index) => (
                    <RepeatedDiv key={index} />
                  ))}
                </div>
              )}
              <div className="mx-auto grid w-full max-w-[1560px] py-4">
                <div className="pagination mt-2 flex w-full items-end justify-end gap-4">
                  {totalPages === 0 ? (
                    <></>
                  ) : (
                    <button
                      className="flex items-center justify-center rounded-lg border border-gray-300 bg-white p-2"
                      onClick={scrollToTop}
                    >
                      <ChevronUpIcon />
                    </button>
                  )}
                  {totalPages === 0 ? (
                    <></>
                  ) : (
                    <div className="flex h-8 items-center -space-x-px text-sm">
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center rounded-s-lg border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectFirst}
                      >
                        <DoubleArrowLeftIcon />
                      </button>
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectDec}
                      >
                        <ChevronLeftIcon />
                      </button>
                      {renderPageNumbers()}
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center border border-s-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectInc}
                      >
                        <ChevronRightIcon />
                      </button>
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center rounded-e-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectLast}
                      >
                        <DoubleArrowRightIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FilteredPaginatedProductGridWatch;
