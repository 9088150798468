import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import CasioLogo from "../../assets/icons/CASIO.png";
import UnionMood from "../../assets/images/51ea6df4329fcf1cc0074c9220ef2d618945fd91_D009.427.16.032.09_04.jpg";
import Union from "../../assets/icons/Union_Glashütte_logo.svg.png";
import UnionAlt from "../../assets/icons/UNION-Logo-weiss.png";
import CasioLogoDark from "../../assets/icons/casio-1121.svg";
import CasioMoodImage from "../../assets/icons/EQB-1100D-1AER_EDIFICE_2022_Lifestyle_mk (5).webp";
import CompLogoMuehle from "../../assets/icons/Logo_Muehle_black_400px.png";
import NautischeLogo from "../../assets/icons/Logo_SVG_White.svg";
import ThomasSabo from "../../assets/icons/ThomasSabo.png";
import CompLogoElaine from "../../assets/icons/elaineFirenze.webp";
import TutimaLogo from "../../assets/icons/header-logo.webp";
import CompLogoBruno from "../../assets/icons/logo_(1).svg";
import CompLogoTutima from "../../assets/icons/logo_big.svg";
import CompLogoMido from "../../assets/icons/mido_0.png";
import MoodTissot from "../../assets/images/classic_homme_banner_2020.avif";
import MoodMido from "../../assets/images/mido_watchwinder_1080x810_1.avif";
import CompLogoTissot from "../../assets/icons/tissot-white.svg";
import CompLogoTissotDark from "../../assets/icons/tissot-dark.svg";
import MuehleMoodImage from "../../assets/images/Jv98wj1N.jpeg";
import TS from "../../assets/images/ts/Screenshot 2023-11-01 193105.png";
import Tu from "../../assets/images/Tutima_180113_V1_040-2.webp";
import FavSlider from "../../components/slider/FavSlider";
import WelcomeParallax from "../../components/welcome/WelcomeParallax";
import "./HomePage.css";
import NewsSlider from "../../components/slider/NewsSlider";
import MainSlider from "../../components/slider/MainSlider";
import bg from "../../assets/images/iStock-1180931397_XL.jpg";
import service from "../../assets/images/Restaurierung.jpg";
import RingSlider from "../../components/slider/RingSlider";

function HomePage() {
  return (
    <div className="">
      <WelcomeParallax imageUrl={bg}></WelcomeParallax>
      <span id="scroll-entry"></span>
      <Marquee
        className="h-[100px]"
        autoFill
        gradient
        gradientWidth={100}
        gradientColor="#fff"
        speed={20}
        pauseOnHover
      >
        <img
          src={CompLogoTissotDark}
          alt=""
          className="h-[50px] w-[100px] px-2 text-sm font-medium xl:h-[100px] xl:w-[150px] xl:px-4 xl:text-xl"
        />
        <img
          src={CompLogoMido}
          alt=""
          className="w-[100px] px-2 text-sm font-medium xl:w-[150px] xl:px-4 xl:text-xl"
        />
        <img
          src={CompLogoMuehle}
          alt=""
          className="w-[100px] px-2 text-sm font-medium xl:w-[150px] xl:px-4 xl:text-xl"
        />
        <img
          src={CompLogoTutima}
          alt=""
          className="w-[100px] px-2 text-sm font-medium xl:w-[150px] xl:px-4 xl:text-xl"
        />
        <img
          src={CompLogoBruno}
          alt=""
          className="w-[100px] px-2 text-sm font-medium xl:w-[150px] xl:px-4 xl:text-xl"
        />
        <img
          src={CompLogoElaine}
          alt=""
          className="w-[100px] px-2 text-sm font-medium xl:w-[150px] xl:px-4 xl:text-xl"
        />
        <img
          src={CasioLogoDark}
          alt=""
          className="w-[100px] px-2 text-sm font-medium xl:w-[150px] xl:px-4 xl:text-xl"
        />
        <img
          src={Union}
          alt=""
          className="w-[100px] px-2 text-sm font-medium xl:w-[150px] xl:px-4 xl:text-xl"
        />
      </Marquee>
      <div className="homepageContainer bg-gray-white gap-6 pt-20">
        <div className="mx-auto flex w-[calc(100%-48px)] max-w-[1920px] items-center justify-center gap-8 bg-gray-200 py-8">
          <h1 className="m-0 w-max p-0 text-6xl font-semibold uppercase">
            Unsere Favoriten
          </h1>
        </div>
        <section className="homepageContainer__featured flex-1">
          <Link
            to={"/THOMAS-SABO&page=1"}
            className="homepageContainer__featured-content"
          >
            <img
              src={TS}
              className="@apply h-[450px] w-full object-cover brightness-[60%]  saturate-[120%]"
              alt=""
            />
            <div className="absolute flex h-full w-full flex-col items-center justify-end gap-6 pb-16">
              <img
                src={ThomasSabo}
                className="w-[140px] md:w-[70px] xl:block xl:w-[300px]"
                alt=""
              />
              <Link
                to={"/THOMAS-SABO?page=1"}
                className="flex items-center justify-center gap-2 bg-gray-50 px-4 py-2 text-base font-medium text-gray-900 hover:bg-blue-500 hover:text-gray-50"
              >
                Jetzt entdecken
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
          <Link
            to={"/UHREN?page=1&brand=TUTIMA%20Glashütte"}
            className="homepageContainer__featured-content"
          >
            <img
              src={Tu}
              className="@apply h-[450px] w-full object-cover brightness-[60%]  saturate-[120%]"
              alt="Tutima Glashütte"
            />
            <div className="absolute flex h-full w-full flex-col items-center justify-end gap-6 pb-16">
              <img
                src={TutimaLogo}
                className="w-[140px] md:w-[70px] xl:block xl:w-[200px]"
                alt=""
              />
              <Link
                to={"/UHREN?page=1&brand=TUTIMA%20Glashütte"}
                className=" flex items-center justify-center gap-2 bg-gray-50 px-4 py-2 text-base font-medium text-gray-900 hover:bg-blue-500 hover:text-gray-50"
              >
                Jetzt entdecken
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
          <Link
            to={"/UHREN?page=1&brand=Mühle%20Glashütte"}
            className="homepageContainer__featured-content"
          >
            <img
              src={MuehleMoodImage}
              className="@apply h-[450px] w-full object-cover brightness-[60%]  saturate-[120%]"
              alt=""
            />
            <div className="absolute flex h-full w-full flex-col items-center justify-end gap-6 pb-16">
              <img
                src={NautischeLogo}
                className="w-[140px] md:w-[70px] xl:block xl:w-[300px]"
                alt=""
              />
              <Link
                to={"/UHREN?page=1&brand=Mühle%20Glashütte"}
                className=" flex items-center justify-center gap-2 bg-gray-50 px-4 py-2 text-base font-medium text-gray-900 hover:bg-blue-500 hover:text-gray-50"
              >
                Jetzt entdecken
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
          <Link
            to={"/UHREN?page=1&brand=UNION%20Glashütte"}
            className="homepageContainer__featured-content"
          >
            <img
              src={UnionMood}
              className="@apply h-[450px] w-full object-cover brightness-[60%]  saturate-[120%]"
              alt=""
            />
            <div className="absolute flex h-full w-full flex-col items-center justify-end gap-6 pb-16">
              <img
                src={UnionAlt}
                className="w-[140px] md:w-[70px] xl:block xl:w-[300px]"
                alt=""
              />
              <Link
                to={"/UHREN?page=1&brand=UNION%20Glashütte"}
                className=" flex items-center justify-center gap-2 bg-gray-50 px-4 py-2 text-base font-medium text-gray-900 hover:bg-blue-500 hover:text-gray-50"
              >
                Jetzt entdecken
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
          <Link
            to={"/UHREN?page=1&brand=Mido"}
            className="homepageContainer__featured-content"
          >
            <img
              src={MoodMido}
              className="@apply h-full min-h-[300px] w-full object-cover brightness-[60%]  saturate-[120%]"
              alt=""
            />
            <div className="absolute flex h-full w-full flex-col items-center justify-end gap-6 pb-16">
              <div className="h-[140px] w-[140px] items-center justify-center md:w-[40px] xl:flex xl:w-[300px]">
                <svg
                  width="140"
                  height="40"
                  viewBox="0 0 140 40"
                  fill="none"
                  version="1.1"
                  id="svg66"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)" id="g56">
                    <g clipPath="url(#clip1)" id="g54">
                      <path
                        d="M0.0426415 38.0868C0.156856 38.2474 0.309599 38.3772 0.487125 38.4648C0.664652 38.5524 0.861392 38.5949 1.05964 38.5885C1.4875 38.5885 2.01575 38.3214 2.01575 37.7985C2.01575 36.704 -0.350664 37.3816 -0.350664 35.5313C-0.350664 34.9742 0.123277 34.2021 1.27686 34.2021C1.79853 34.2021 2.30044 34.3096 2.65919 34.7462L2.13094 35.2218C2.034 35.0884 1.90536 34.9808 1.7564 34.9084C1.60743 34.8359 1.44274 34.8009 1.27686 34.8064C0.557723 34.8064 0.3405 35.2364 0.3405 35.5313C0.3405 36.7317 2.70691 36.0199 2.70691 37.7253C2.70691 38.6423 1.96144 39.1912 1.03989 39.1912C0.422782 39.1912 -0.167999 38.9843 -0.492188 38.5397L0.0426415 38.0868Z"
                        fill="#fff"
                        id="path2"
                      />
                      <path
                        d="M5.29688 34.3301H5.97323L7.03137 38.1268H7.04453L8.17672 34.3301H8.92055L10.0527 38.1268H10.0675L11.124 34.3301H11.802L10.4049 39.078H9.72032L8.55851 35.2031H8.54205L7.39011 39.0797H6.69895L5.29688 34.3301Z"
                        fill="#fff"
                        id="path4"
                      />
                      <path
                        d="M14.6641 34.3301H15.3223V39.078H14.6641V34.3301Z"
                        fill="#fff"
                        id="path6"
                      />
                      <path
                        d="M18.9255 38.0872C19.0389 38.2489 19.1913 38.38 19.3689 38.4688C19.5465 38.5575 19.7436 38.601 19.9425 38.5954C20.3687 38.5954 20.8969 38.3282 20.8969 37.8054C20.8969 36.7108 18.5321 37.3884 18.5321 35.5381C18.5321 34.981 19.0077 34.209 20.158 34.209C20.6813 34.209 21.1833 34.3165 21.542 34.753L21.0121 35.2286C20.9155 35.095 20.7869 34.9871 20.6379 34.9146C20.4888 34.8421 20.324 34.8073 20.158 34.8133C19.4405 34.8133 19.2233 35.2433 19.2233 35.5381C19.2233 36.7385 21.5897 36.0267 21.5897 37.7321C21.5897 38.6491 20.8426 39.198 19.9211 39.198C19.304 39.198 18.7165 38.9912 18.3906 38.5465L18.9255 38.0872Z"
                        fill="#fff"
                        id="path8"
                      />
                      <path
                        d="M24.9333 38.0872C25.0467 38.2489 25.1992 38.38 25.3767 38.4688C25.5543 38.5575 25.7514 38.601 25.9503 38.5954C26.3765 38.5954 26.9047 38.3282 26.9047 37.8054C26.9047 36.7108 24.5416 37.3884 24.5416 35.5381C24.5416 34.981 25.0155 34.209 26.1675 34.209C26.6892 34.209 27.1911 34.3165 27.5498 34.753L27.0216 35.2286C26.9249 35.095 26.7964 34.9871 26.6473 34.9146C26.4983 34.8421 26.3334 34.8073 26.1675 34.8133C25.4483 34.8133 25.2328 35.2433 25.2328 35.5381C25.2328 36.7385 27.5975 36.0267 27.5975 37.7321C27.5975 38.6491 26.8521 39.198 25.9305 39.198C25.3134 39.198 24.7243 38.9912 24.3984 38.5465L24.9333 38.0872Z"
                        fill="#fff"
                        id="path10"
                      />
                      <path
                        d="M34.3086 34.3301H34.9866L36.0414 38.1268H36.0563L37.1934 34.3301H37.9389L39.0694 38.1268H39.0826L40.1407 34.3301H40.8138L39.4199 39.078H38.7353L37.5686 35.2015H37.5554L36.4035 39.078H35.7123L34.3086 34.3301Z"
                        fill="#fff"
                        id="path12"
                      />
                      <path
                        d="M44.6967 34.3301H45.2874L47.3395 39.078H46.5743L46.0938 37.9037H43.8163L43.3407 39.078H42.582L44.6967 34.3301ZM45.8634 37.3417L44.9682 35.1689H44.955L44.0466 37.3482L45.8634 37.3417Z"
                        fill="#fff"
                        id="path14"
                      />
                      <path
                        d="M50.2171 34.9344H48.6719V34.3301H52.4124V34.9344H50.8688V39.078H50.2105L50.2171 34.9344Z"
                        fill="#fff"
                        id="path16"
                      />
                      <path
                        d="M58.1152 35.3573C57.9769 35.1867 57.8015 35.0492 57.6021 34.955C57.4026 34.8608 57.1843 34.8124 56.9633 34.8133C55.8854 34.8133 55.1745 35.6651 55.1745 36.745C55.1745 37.7435 55.8443 38.5954 56.9633 38.5954C57.2093 38.6007 57.4528 38.5452 57.6717 38.434C57.8907 38.3228 58.0782 38.1594 58.2173 37.9585L58.7669 38.3673C58.5511 38.6355 58.2752 38.8503 57.961 38.9948C57.6468 39.1393 57.303 39.2094 56.9567 39.1996C56.3021 39.1996 55.6742 38.9422 55.2113 38.484C54.7483 38.0258 54.4883 37.4044 54.4883 36.7564V36.745C54.4883 35.2693 55.5119 34.209 56.9896 34.209C57.6199 34.209 58.2831 34.4435 58.6698 34.9468L58.1152 35.3573Z"
                        fill="#fff"
                        id="path18"
                      />
                      <path
                        d="M61.4609 34.3301H62.111V36.3091H64.5992V34.3301H65.2492V39.078H64.5992V36.9068H62.111V39.078H61.4609V34.3301Z"
                        fill="#fff"
                        id="path20"
                      />
                      <path
                        d="M68.8828 34.3301H71.9799V34.9344H69.5328V36.3498H71.8104V36.9606H69.5328V38.4835H72.1017V39.078H68.8828V34.3301Z"
                        fill="#fff"
                        id="path22"
                      />
                      <path
                        d="M75.3724 38.0872C75.4855 38.2489 75.6376 38.38 75.8149 38.4688C75.9923 38.5575 76.1892 38.601 76.3878 38.5954C76.8156 38.5954 77.3439 38.3282 77.3439 37.8054C77.3439 36.7108 74.9791 37.3884 74.9791 35.5381C74.9791 34.981 75.453 34.209 76.605 34.209C77.1266 34.209 77.6286 34.3165 77.989 34.753L77.4591 35.2286C77.3624 35.095 77.2338 34.9871 77.0848 34.9146C76.9358 34.8421 76.7709 34.8073 76.605 34.8133C75.8875 34.8133 75.6703 35.2433 75.6703 35.5381C75.6703 36.7385 78.035 36.0267 78.035 37.7321C78.035 38.6491 77.2896 39.198 76.368 39.198C75.7526 39.198 75.1618 38.9912 74.8359 38.5465L75.3724 38.0872Z"
                        fill="#fff"
                        id="path24"
                      />
                      <path
                        d="M85.5739 38.0872C85.6876 38.2487 85.84 38.3797 86.0176 38.4684C86.1951 38.5571 86.3921 38.6007 86.5909 38.5954C87.0187 38.5954 87.547 38.3282 87.547 37.8054C87.547 36.7108 85.1822 37.3884 85.1822 35.5381C85.1822 34.981 85.6562 34.209 86.8098 34.209C87.3298 34.209 87.8317 34.3165 88.1921 34.753L87.6622 35.2286C87.5655 35.0954 87.4371 34.9878 87.2885 34.9154C87.1398 34.8429 86.9754 34.8079 86.8098 34.8133C86.089 34.8133 85.8734 35.2433 85.8734 35.5381C85.8734 36.7385 88.2382 36.0267 88.2382 37.7321C88.2382 38.6491 87.4927 39.198 86.5711 39.198C85.9557 39.198 85.3649 38.9912 85.0391 38.5465L85.5739 38.0872Z"
                        fill="#fff"
                        id="path26"
                      />
                      <path
                        d="M91.4766 34.3301H92.1348V39.078H91.4766V34.3301Z"
                        fill="#fff"
                        id="path28"
                      />
                      <path
                        d="M95.5977 34.3301H96.4501L99.0946 38.2392H99.1078V34.3301H99.7661V39.078H98.9432L96.2707 35.1689H96.2559V39.078H95.5977V34.3301Z"
                        fill="#fff"
                        id="path30"
                      />
                      <path
                        d="M106.691 35.3573C106.552 35.1873 106.377 35.0503 106.177 34.9562C105.978 34.862 105.76 34.8132 105.539 34.8133C104.461 34.8133 103.75 35.6651 103.75 36.745C103.75 37.7435 104.42 38.5954 105.539 38.5954C105.785 38.6004 106.028 38.5448 106.247 38.4336C106.465 38.3224 106.653 38.1592 106.791 37.9585L107.341 38.3673C107.125 38.6355 106.849 38.8503 106.535 38.9948C106.221 39.1393 105.877 39.2094 105.531 39.1996C104.876 39.1996 104.248 38.9422 103.785 38.484C103.323 38.0258 103.062 37.4044 103.062 36.7564V36.745C103.062 35.2693 104.086 34.209 105.564 34.209C106.194 34.209 106.859 34.4435 107.244 34.9468L106.691 35.3573Z"
                        fill="#fff"
                        id="path32"
                      />
                      <path
                        d="M110.441 34.3301H113.538V34.9344H111.091V36.3498H113.369V36.9606H111.091V38.4835H113.66V39.078H110.441V34.3301Z"
                        fill="#fff"
                        id="path34"
                      />
                      <path
                        d="M121.79 35.0679L120.91 35.8726L120.531 35.4246L121.866 34.3301H122.437V39.078H121.779L121.79 35.0679Z"
                        fill="#fff"
                        id="path36"
                      />
                      <path
                        d="M127.639 37.2421H127.613C127.446 37.3166 127.265 37.3533 127.083 37.3496C126.7 37.3592 126.329 37.2179 126.051 36.9568C125.773 36.6957 125.611 36.336 125.602 35.957C125.602 35.9212 125.602 35.8837 125.602 35.8462C125.602 34.8005 126.347 34.25 127.247 34.25C128.147 34.25 128.893 34.8527 128.893 35.7794C128.893 36.3626 128.702 36.7258 128.411 37.1818L127.199 39.0778H126.434L127.639 37.2421ZM127.247 34.8136C127.05 34.8116 126.857 34.8676 126.692 34.9745C126.526 35.0813 126.397 35.2342 126.32 35.4139C126.243 35.5935 126.221 35.7917 126.257 35.9836C126.294 36.1754 126.387 36.3522 126.525 36.4916C126.663 36.631 126.84 36.7267 127.033 36.7667C127.226 36.8067 127.427 36.7891 127.61 36.7162C127.793 36.6433 127.95 36.5183 128.061 36.3571C128.172 36.1958 128.233 36.0056 128.235 35.8104V35.799C128.247 35.55 128.158 35.3065 127.989 35.1218C127.82 34.9371 127.584 34.8262 127.333 34.8136H127.247Z"
                        fill="#fff"
                        id="path38"
                      />
                      <path
                        d="M132.639 35.0679L131.759 35.8726L131.379 35.4246L132.714 34.3301H133.298V39.078H132.639V35.0679Z"
                        fill="#fff"
                        id="path40"
                      />
                      <path
                        d="M136.32 37.7177C136.318 37.4505 136.407 37.1905 136.573 36.9799C136.74 36.7693 136.973 36.6206 137.235 36.558V36.5368C136.875 36.4424 136.482 36.0873 136.482 35.4309C136.482 34.7126 137.14 34.25 137.885 34.25C138.631 34.25 139.289 34.7126 139.289 35.4309C139.289 36.0824 138.896 36.4424 138.535 36.5368V36.558C138.798 36.6206 139.031 36.7693 139.197 36.9799C139.363 37.1905 139.453 37.4505 139.45 37.7177C139.45 38.6168 138.759 39.1608 137.885 39.1608C137.012 39.1608 136.32 38.6168 136.32 37.7177ZM138.8 37.7177C138.8 37.1411 138.394 36.8463 137.885 36.8398C137.77 36.8352 137.654 36.8532 137.546 36.8929C137.437 36.9325 137.337 36.9929 137.252 37.0706C137.167 37.1484 137.099 37.242 137.05 37.346C137.002 37.4501 136.975 37.5625 136.97 37.677V37.7177C136.97 37.9579 137.067 38.1883 137.238 38.3581C137.41 38.5279 137.643 38.6233 137.885 38.6233C138.128 38.6233 138.361 38.5279 138.532 38.3581C138.704 38.1883 138.8 37.9579 138.8 37.7177ZM137.885 36.2762C138.039 36.2833 138.19 36.2443 138.321 36.1644C138.451 36.0846 138.554 35.9675 138.616 35.8286C138.678 35.6897 138.696 35.5356 138.668 35.3863C138.64 35.2371 138.567 35.0998 138.458 34.9924C138.35 34.8851 138.211 34.8127 138.06 34.7848C137.91 34.757 137.754 34.7749 137.613 34.8362C137.473 34.8976 137.355 34.9995 137.274 35.1286C137.193 35.2577 137.154 35.4079 137.161 35.5596C137.17 35.7468 137.249 35.9241 137.383 36.0566C137.517 36.1892 137.696 36.2675 137.885 36.2762Z"
                        fill="#fff"
                        id="path42"
                      />
                      <path
                        d="M18.2188 0.601562H23.2774L30.8621 18.6177L38.2855 0.601562H43.2899V24.7078H39.6053V5.97661H39.5378L31.884 24.7078H29.6213L21.7223 5.97661H21.6532V24.7078H18.2188V0.601562Z"
                        fill="#fff"
                        id="path44"
                      />
                      <path
                        d="M53.793 0.601562H57.2685V24.7078H53.793V0.601562Z"
                        fill="#fff"
                        id="path46"
                      />
                      <path
                        d="M68.1914 0.601562H77.7871C84.1852 0.601562 89.2867 4.483 89.2867 12.6547C89.2867 20.8948 83.1189 24.7078 77.2736 24.7078H68.1914V0.601562ZM75.5095 21.3867C81.0865 21.3867 85.6383 18.6601 85.6383 12.6514C85.6383 6.64279 81.6888 3.76144 76.2583 3.76144H71.8694V21.3867H75.5095Z"
                        fill="#fff"
                        id="path48"
                      />
                      <path
                        d="M108.219 -0.0138827C115.82 -0.150702 121.187 4.88718 121.187 12.6158C121.187 20.1393 115.82 25.1772 108.219 25.3205C100.72 25.3205 95.3555 20.2826 95.3555 12.7592C95.3555 5.0354 100.714 -0.0138827 108.219 -0.0138827ZM108.252 22.0075C113.64 22.0075 117.196 17.9453 117.196 12.4676C117.196 7.35319 113.64 3.29096 108.252 3.29096C102.902 3.29096 99.3461 7.35319 99.3461 12.4676C99.3461 17.9485 102.902 22.0108 108.252 22.0108V22.0075Z"
                        fill="#fff"
                        id="path50"
                      />
                      <path
                        d="M123.259 20.3662C123.758 20.3662 124.245 20.5126 124.66 20.7867C125.075 21.0609 125.398 21.4507 125.589 21.9066C125.779 22.3626 125.829 22.8643 125.732 23.3484C125.635 23.8324 125.395 24.277 125.042 24.626C124.689 24.975 124.24 25.2126 123.751 25.3089C123.262 25.4052 122.755 25.3558 122.295 25.1669C121.834 24.9781 121.44 24.6582 121.163 24.2479C120.886 23.8375 120.738 23.3551 120.738 22.8615C120.738 22.1997 121.004 21.565 121.477 21.0971C121.949 20.6291 122.591 20.3662 123.259 20.3662ZM123.259 24.9073C123.669 24.9073 124.07 24.7869 124.411 24.5614C124.752 24.3359 125.018 24.0154 125.175 23.6404C125.332 23.2654 125.373 22.8527 125.293 22.4546C125.213 22.0565 125.016 21.6908 124.726 21.4038C124.436 21.1168 124.066 20.9214 123.664 20.8422C123.262 20.763 122.845 20.8036 122.466 20.959C122.087 21.1143 121.763 21.3773 121.535 21.7148C121.308 22.0523 121.186 22.4491 121.186 22.855C121.186 23.3993 121.404 23.9213 121.793 24.3062C122.182 24.6911 122.709 24.9073 123.259 24.9073ZM122.29 21.4135H123.403C124.094 21.4135 124.418 21.7214 124.418 22.2719C124.435 22.4619 124.375 22.6507 124.252 22.7972C124.128 22.9438 123.951 23.036 123.76 23.0537H123.728L124.48 24.2623H123.918L123.2 23.0945H122.813V24.2623H122.285L122.29 21.4135ZM122.818 22.6531H123.205C123.534 22.6531 123.89 22.6335 123.89 22.2507C123.89 21.868 123.531 21.8484 123.205 21.8549H122.818V22.6531Z"
                        fill="#fff"
                        id="path52"
                      />
                    </g>
                  </g>
                  <defs id="defs64">
                    <clipPath id="clip0">
                      <rect
                        width="140"
                        height="39.2"
                        fill="white"
                        id="rect58"
                      />
                    </clipPath>
                    <clipPath id="clip1">
                      <rect
                        width="140"
                        height="42.6734"
                        fill="white"
                        transform="translate(-0.492188 -3.47363)"
                        id="rect61"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <Link
                to={"/UHREN?page=1&brand=Mido"}
                className=" flex items-center justify-center gap-2 bg-gray-50 px-4 py-2 text-base font-medium text-gray-900 hover:bg-blue-500 hover:text-gray-50"
              >
                Jetzt entdecken
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
          <Link
            to={"/UHREN?page=1&brand=Tissot"}
            className="homepageContainer__featured-content"
          >
            <img
              src={MoodTissot}
              className="@apply h-full min-h-[300px] w-full object-cover brightness-[60%] saturate-[120%]"
              alt=""
            />
            <div className="absolute flex h-full w-full flex-col items-center justify-end gap-6 pb-16">
              <img
                src={CompLogoTissot}
                className="w-[140px] md:w-[70px] xl:block xl:w-[300px]"
                alt=""
              />
              <Link
                to={"/UHREN?page=1&brand=Tissot"}
                className=" flex items-center justify-center gap-2 bg-gray-50 px-4 py-2 text-base font-medium text-gray-900 hover:bg-blue-500 hover:text-gray-50"
              >
                Jetzt entdecken
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.1584 3.13508C6.35985 2.94621 6.67627 2.95642 6.86514 3.15788L10.6151 7.15788C10.7954 7.3502 10.7954 7.64949 10.6151 7.84182L6.86514 11.8418C6.67627 12.0433 6.35985 12.0535 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1579L9.565 7.49985L6.1356 3.84182C5.94673 3.64036 5.95694 3.32394 6.1584 3.13508Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </Link>
        </section>
        {/* <span className="mx-auto mt-12 flex max-w-[1920px] flex-col items-center justify-center">
          <h1 className="font-Geist text-3xl">
            Unsere Ode an Qualität und zeitloses Design.
          </h1>
          <p className="max-w-1/3 mt-6 text-center font-Geist text-base">
            Seit Jahren ist time&trend in Plauen für Sie am Ort und trägt die
            aktuellsten Trends und moderne Uhren und Schmuck zusammen.
          </p>
        </span>
        <div className="relative mx-auto flex w-full max-w-[1920px] items-center justify-center overflow-hidden bg-gray-200">
          <img
            src={tt}
            className="absolute z-30 mx-auto object-contain"
            alt=""
          />
          <img
            src={tt}
            className="mx-auto h-[350px] w-full object-cover blur-2xl brightness-50"
            alt=""
          />
        </div>*/}
        <div className="mx-auto flex w-[calc(100%-48px)] max-w-[1920px] items-center justify-center bg-gray-200 py-8">
          <h1 className="m-0 w-max p-0 text-base font-semibold uppercase lg:text-3xl  2xl:text-6xl">
            Trauringe&nbsp;&&nbsp;Verlobungsringe
          </h1>
        </div>
        <RingSlider />
        <div className="mx-auto mt-16 flex w-[calc(100%-48px)] max-w-[1920px] items-center justify-center bg-gray-200 py-8">
          <h1 className="m-0 w-max p-0 text-base font-semibold uppercase lg:text-3xl 2xl:text-6xl">
            Unsere Kollektionen 2024
          </h1>
        </div>
      </div>
      <FavSlider></FavSlider>
      <NewsSlider></NewsSlider>
      <MainSlider></MainSlider>
      {/* <div className="w-full bg-gray-200 py-12">
        <span className="mx-auto flex w-[1920px] items-center justify-center pb-8">
          <h1 className="font-Geist mx-auto w-full max-w-[1920px] text-center text-base font-semibold uppercase text-gray-500 xl:text-3xl">
            Unsere&nbsp;
            <span className="text-gray-950">Sonder-Serviceleistungen</span>
            &nbsp;für geliebte Sammlerstücke.
          </h1>
        </span>
        <div className="mx-auto flex w-full items-center justify-center">
          <img
            src={service}
            className="mx-auto w-[400px] border-8 border-gray-50 object-contain"
            alt=""
          />
        </div>
      </div> */}
    </div>
  );
}

export default HomePage;
